<template>
    <div class="min-h-full">
        <Popover as="header" class="pb-24 bg-primary-600" v-slot="{ open }">
            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div class="relative py-5 flex items-center justify-center lg:justify-between">
                    <!-- Logo -->
                    <div class="absolute left-0 flex-shrink-0 lg:static">
                        <div>
                            <span class="sr-only">WeTix</span>
                            <img class="h-8 w-auto" src="~/assets/wetix-emblem-wings-light-grayscale.svg" alt="WeTix" />
                            <!-- <LayoutAppSwitcher></LayoutAppSwitcher> -->
                        </div>

                    </div>


                    <!-- Right section on desktop -->
                    <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">

                        <button type="button"
                            class="flex-shrink-0 p-1 text-primary-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                            <span class="sr-only">Switch Event</span>
                            <SwitchHorizontalIcon class="h-6 w-6" aria-hidden="true" />
                        </button>

                        <button type="button"
                            class="flex-shrink-0 p-1 text-primary-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                            <span class="sr-only">View notifications</span>
                            <BellIcon class="h-6 w-6" aria-hidden="true" />
                        </button>

                        <!-- Profile dropdown -->
                        <Menu as="div" class="ml-4 relative flex-shrink-0">
                            <div>
                                <MenuButton
                                    class="bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                                    <span class="sr-only">Open user menu</span>
                                    <img class="h-8 w-8 rounded-full" :src="user.imageUrl" alt="" />
                                </MenuButton>
                            </div>
                            <transition leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95">
                                <MenuItems
                                    class="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                    <NuxtLink :href="item.href"
                                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                                        {{
                                            item.name
                                        }}</NuxtLink>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>

                    <!-- Search -->
                    <div class="flex-1 min-w-0 px-12 lg:hidden">
                        <div class="max-w-xs w-full mx-auto">
                            <label for="desktop-search" class="sr-only">Search</label>
                            <div class="relative text-white focus-within:text-gray-600">
                                <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <SearchIcon class="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input id="desktop-search"
                                    class="block w-full bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                                    placeholder="Search" type="search" name="search" />
                            </div>
                        </div>
                    </div>

                    <!-- Menu button -->
                    <div class="absolute right-0 flex-shrink-0 lg:hidden">
                        <!-- Mobile menu button -->
                        <PopoverButton
                            class="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-primary-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                            <span class="sr-only">Open main menu</span>
                            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </PopoverButton>
                    </div>
                </div>
                <div class="hidden lg:block border-t border-white border-opacity-20 py-5">
                    <div class="grid grid-cols-3 gap-8 items-center">
                        <div class="col-span-2">
                            <nav class="flex space-x-4">
                                <NuxtLink v-for="item in navigation" :key="item.name" :href="item.href"
                                    :class="[item.current ? 'text-white' : 'text-primary-100', 'text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10']"
                                    :aria-current="item.current ? 'page' : undefined">
                                    {{ item.name }}
                                </NuxtLink>
                            </nav>
                        </div>
                        <div>
                            <div class="max-w-md w-full mx-auto">
                                <label for="mobile-search" class="sr-only">Search</label>
                                <div class="relative text-white focus-within:text-gray-600">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                        <SearchIcon class="h-5 w-5" aria-hidden="true" />
                                    </div>
                                    <input id="mobile-search"
                                        class="block w-full bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                                        placeholder="Search" type="search" name="search" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TransitionRoot as="template" :show="open">
                <div class="lg:hidden">
                    <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0"
                        enter-to="opacity-100" leave="duration-150 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                        <PopoverOverlay class="z-20 fixed inset-0 bg-black bg-opacity-25" />
                    </TransitionChild>

                    <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-150 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <PopoverPanel focus
                            class="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top">
                            <div
                                class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                                <div class="pt-3 pb-2">
                                    <div class="flex items-center justify-between px-4">
                                        <div>
                                            <img class="h-8 w-auto" src="~/assets/wetix-emblem-wings.png" alt="WeTix" />
                                        </div>
                                        <div class="-mr-2">
                                            <PopoverButton
                                                class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                                <span class="sr-only">Close menu</span>
                                                <XIcon class="h-6 w-6" aria-hidden="true" />
                                            </PopoverButton>
                                        </div>
                                    </div>
                                    <div class="mt-3 px-2 space-y-1">
                                        <a href="#"
                                            class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">Console</a>
                                        <a href="#"
                                            class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">Guest
                                            List</a>
                                        <a href="#"
                                            class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">History</a>
                                        <a href="#"
                                            class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">Device
                                            Settings</a>
                                        <a href="#"
                                            class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">Help</a>
                                    </div>
                                </div>
                                <div class="pt-4 pb-2">
                                    <div class="flex items-center px-5">
                                        <div class="flex-shrink-0">
                                            <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
                                        </div>
                                        <div class="ml-3 min-w-0 flex-1">
                                            <div class="text-base font-medium text-gray-800 truncate">{{ user.name }}
                                            </div>
                                            <div class="text-sm font-medium text-gray-500 truncate">{{ user.email }}
                                            </div>
                                        </div>
                                        <button type="button"
                                            class="ml-auto flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                            <span class="sr-only">View notifications</span>
                                            <BellIcon class="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div class="mt-3 px-2 space-y-1">
                                        <a v-for="item in userNavigation" :key="item.name" :href="item.href"
                                            class="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">{{
                                                item.name
                                            }}</a>
                                    </div>
                                </div>
                            </div>
                        </PopoverPanel>
                    </TransitionChild>
                </div>
            </TransitionRoot>
        </Popover>
        <main class="-mt-24 pb-8">
            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 class="sr-only">Page title</h1>
                <!-- Main 3 column grid -->
                <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                    <!-- Left column -->
                    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                        <section aria-labelledby="section-1-title">
                            <h2 class="sr-only" id="section-1-title">Ticket Manager</h2>
                            <div class="rounded-lg bg-white overflow-hidden shadow">
                                <div class="p-6">
                                    <!-- MAIN PAGE SLOT -->
                                    <slot />
                                </div>
                            </div>
                        </section>
                    </div>

                    <!-- Right column -->
                    <div class="grid grid-cols-1 gap-4">
                        <section aria-labelledby="section-2-title">
                            <h2 class="sr-only" id="section-2-title">Section title</h2>
                            <div class="rounded-lg bg-white overflow-hidden shadow">
                                <div class="p-6">
                                    <!-- Your content -->
                                    <ScanSearchResultsList />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
        <footer>

            <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div class="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
                    <div class="text-xs">Type or scan a name, order # or or scan a ticket ID in the search box (auto
                        focuses).
                        Search results will be listed on the right. If a specific ID is entered, the result will
                        automatically be selected. Upon select, simply nuxt link to a sub-page with the order details in
                        the left-side card. A query param on that component takes you to a specific ticket.</div>
                    <div class="text-xs">When a search result is selected on the right, all tickets
                        found for the order
                        will be listed here, showing their status etc and button to check them
                        in/not. A close button will enable closing the order, returning to the console
                        route. in which case this box will return
                        to empty state.</div>
                    <div class="text-xs">Notes - Make a pinia store to handle all logic. Add a 'auto check-in' mode
                        which automatically checks in any scans. Add toggle button to switch between scanning or
                        searching when user starts to type. background color of topbar changes to red if invalid ticket
                        is scanned.
                        Add
                        online/offline
                        indicators at top. If offline, show bar along top. Add a 'login with QR code' option which
                        simply uses a QR printed on badges to log a user in with limited privaledges. Trigger an
                        initialisation dialog if no localstorage access control config doc exists and get user to
                        regiser the device with the tenant. Use localStorage to cache device ID and user creds.</div>
                    <span class="block sm:inline">&copy; 2022 WeTix Ltd.</span> <span class="block sm:inline">All
                        rights reserved.</span>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup lang="ts">
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,

} from '@headlessui/vue'
import { BellIcon, MenuIcon, XIcon, SwitchHorizontalIcon } from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'

const user = {
    name: 'WeTix User',
    email: 'accesscontrol@wetix.net',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    { name: 'Scan', href: '/app/access-control/scan', current: true },
    { name: 'Guestlist', href: '/app/access-control/guestlist', current: false },
    { name: 'History', href: '/app/access-control/history', current: false },
    { name: 'Summary', href: '/app/access-control/', current: false },
]
const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]
</script>