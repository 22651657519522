<template>
    <div>
        <div class="flow-root mt-6">
            <ul role="list" class="-my-5 divide-y divide-gray-200">
                <li v-for="item in people" :key="item.entityType" class="py-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-shrink-0">
                            <img class="h-8 w-8 rounded-full" :src="item.imageUrl" alt="" />
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                {{ item.name }}
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                {{ item.entityType }}
                            </p>
                        </div>
                        <div>
                            <NuxtLink :href="item.href"
                                class="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                                View </NuxtLink>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mt-6">
            <a href="#"
                class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Show More </a>
        </div>
    </div>
</template>

<script setup lang="ts">
const people = [
    {
        name: 'Tess Tickle',
        entityType: 'Ticket - Tunes in the Dunes 2022',
        href: '/app/access-control/view/ticket?ticketId=WTX22a15b2gqhY6',
        imageUrl:
            'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Ben Dover',
        entityType: 'Order - Tunes in the Dunes',
        href: '/app/access-control/view/order?orderId=W220522J0UWXKK',
        imageUrl:
            'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Bellend McGee',
        entityType: 'Customer',
        href: '/app/access-control/view/customer?customerId=EC00NV636JGX',
        imageUrl:
            'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Dick Long',
        entityType: 'Third-Party Ticket',
        href: '/app/access-control/view/thirdparty-ticket?ticketId=INTU1NT0VP-it_18995494',
        imageUrl:
            'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
]

// TODO: Function to direct the user to the correct route and add query param depending on result type.
</script>